<template>
  <Header></Header>
    <MainMobile
        v-if="isMobile"
        :gclid="gclid"
        :keyword="keyword"
        :matchtype="matchtype"
        :device="device"
        :adposition="adposition"
  >
    </MainMobile>
    <Main v-else></Main>
  <Footer></Footer>
</template>

<script>
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import Main from '@/components/layout/Main.vue';
import MainMobile from "@/components/layout_mobile/MainMobile.vue";

export default {
  name: 'App',
  components: {
    Header,Footer,Main,MainMobile
  },
  data(){
    return {
      isMobile: false,
      gclid: '',
      keyword: '',
      matchtype:'',
      device: '',
      adposition: '',
    }
  },
  methods:{
    checkDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      const mobileKeywords = ['android', 'webos', 'iphone', 'ipad', 'ipod', 'blackberry', 'windows phone'];

      for (const keyword of mobileKeywords) {
        if (userAgent.includes(keyword)) {
          this.isMobile = true;
          break;
        }
      }
    }
  },
  mounted() {
    this.checkDevice();
    const queryString = window.location.search;

    // Create a URLSearchParams object from the query string
    const params = new URLSearchParams(queryString);

    // Access individual parameters by their names
    const gclid       = params.get('gclid');
    const keyword     = params.get('keyword');
    const matchtype   = params.get('matchtype');
    const device      = params.get('device');
    const adposition  = params.get('adposition');

    this.gclid      = gclid;
    this.keyword    = keyword;
    this.matchtype  = matchtype;
    this.device     = device;
    this.adposition = adposition;
  },
}
</script>

<style>

</style>
