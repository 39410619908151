<template>
  <header id="custom_header" class="ni-1i66k3g">

    <div class="ni-8srrvh">
      <a href="/" data-testid="site-logo-link" class="ni-1rd6rc5">
        <img loading="lazy" class="ni-14rsrm"
             src="https://res.cloudinary.com/djvyb0zfe/image/upload/v1680182743/usa/usa_table_no_5/assets/logo-footer-white-background_j8cz8s_a504yi_sb4azy.webp"
             alt="Casino USA" height="23" width="180" />
      </a>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>

</style>