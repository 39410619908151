<template>
  <footer data-role="main-footer" class="ni-1nyzrua">


    <section data-testid="legal-texts" class="ni-15olq5d">

      <section style="text-align: center!important;" data-testid="footer-copyright" class="ni-1yiqr0f text-center">

        <img loading="lazy" src="https://res.cloudinary.com/djvyb0zfe/image/upload/v1680182743/usa/usa_table_no_5/assets/logo-footer-white-background_j8cz8s_a504yi_sb4azy.webp"
             alt="Footer Website Logo"
             style="
                        max-width: 100px;
                        opacity: 0.6;
                        margin-bottom: 5px;
                        margin-top: 10px;"
        >
        <p data-testid="en-copyright" class="ni-euzzdl open-text-center" style="text-align: center!important;">
          Copyright © {{currentYear}} All Rights Reserved.
        </p>
      </section>
    </section>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data(){
    return {
      currentYear: null

    }
  },
  created() {
    const date = new Date();
    this.currentYear = date.getFullYear();
  }
}
</script>

<style scoped>

</style>