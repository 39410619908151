<template>
  <div class="ni-11cnawd">
    <div class="ni-13ekic3">
      <img loading="lazy" class="ni-wrt30h"
           src="https://res.cloudinary.com/djvyb0zfe/image/upload/v1680182742/usa/usa_table_no_5/assets/234sdf092540_djzvem_yehrgg.webp"
           alt="left image"
           width="300"
           height="190">
      <h1 class="ni-6ls74">BEST USA ONLINE CASINO LIST</h1>

      <h2 class="ni-17oh7bq">Last Update {{formattedDate}}</h2>

      <div class="ni-1t51czx">
        <div class="ni-1wt1enj">
          <ul>
            <li>Up To 410% On Your 1st Deposit</li>
            <li>High Payout Slots - 98% RTP </li>
            <li>Reviewed and Approved by Experts</li>
          </ul>
        </div>
      </div>
    </div>
    <div data-testid="trust-toolbar" class="ni-lbl4hh" style="justify-content: space-between!important; margin-bottom: 11px;">
      <div class="ni-1tafh7m">
        <div class="" style="padding-left: 8px;">These casinos are :</div>
        <div class="ni-1sg2lsz tool-bar-icon" data-testid="license-box">
          <img loading="lazy" src="https://res.cloudinary.com/djvyb0zfe/image/upload/v1680182743/usa/usa_table_no_5/assets/united-states-of-america_1_tzz2zo_tmi4hg.webp"
               class="tick-icons"
               style="width: 22px;margin: 0 5px 4px 10px;">
          <div>US Players</div>
        </div>

        <div class="ni-1sg2lsz tool-bar-icon" data-testid="license-box">
          <img loading="lazy" src="https://res.cloudinary.com/djvyb0zfe/image/upload/v1680182742/usa/usa_table_no_5/assets/star_3_ao1coo_eaw6pn.webp"
               class="tick-icons"
               style="width: 22px;margin: 0 5px 4px 10px;">
          <div>Top Rated</div>
        </div>
        <div data-testid="secure-box" class="ni-1l3xxp4 tool-bar-icon">
          <img loading="lazy" src="https://res.cloudinary.com/djvyb0zfe/image/upload/v1680182742/usa/usa_table_no_5/assets/secured_icon_5_rznp2u_zihhyh.webp"
               class="tick-icons"
               style="width: 22px;margin: 0 5px 4px 10px;">
          <div class="ni-1sg2lsz">Secure &amp; Trusted</div>
        </div>

        <div class="ni-h9m0ac tool-bar-icon" data-testid="currency-box">
          <img loading="lazy" src="https://res.cloudinary.com/djvyb0zfe/image/upload/v1680182742/usa/usa_table_no_5/assets/wallet_2_rqt3rp_xnmkkt.webp"
               class="tick-icons"
               style="width: 22px;margin: 0 5px 4px 10px;"   >
          <div>Fast Withdrawals</div>
        </div>
      </div>

    </div>
    <div class="chart-wrapper " data-site-id="130" data-comp-iid="c841caca-800a-49b6-b3e5-eb2d904de729" data-total-products="8" data-visible-products="14" data-visit-again="true" data-visit-text="Visit Again">
      <header class="ni-51ky1g">
        <div class="grey-right-border">

          <span class="ni-1f9atod">casino sites</span>
        </div>
        <div class="grey-right-border">
          <span class="ni-1f9atod">welcome bonus</span>
        </div>
        <div class="grey-right-border">
          <span class="ni-1f9atod">Our Score</span>
        </div>
        <div class="grey-right-border">
          <span class="ni-1f9atod">Ranked</span>
        </div>
        <div >
          <span class="ni-1f9atod">Join us</span>
        </div>
      </header>

      <!-- Casino Table -->
      <div v-for="(cas,index) in sortedCasinos" :key="cas.id"
           :data-product-id="cas.id"
           :data-product-name="cas.name"
           data-hidden-row="false"
           data-product-filters=""
           data-visit-again-row="false"
           data-legacy-brand="false"
           class="ds-chart-row ni-9ilta6"
           style="margin-bottom: 14px;"   >

        <div class="chart-row-columns ni-nu2oo7">

          <div class="ni-1wn2clo" data-testid="partner-logo-column">
            <div data-testid="gaming-ribbon" class="ni-ynjzb2">
              <div
                  v-if="index < 4"
                  data-role="ribbon"
                  data-hook="ribbon"
                  class="ni-12s2yi">
                {{ ribbons[index] }}
              </div>
            </div>
            <a class="nilink"
               @click="gtag_report_conversion('https://spinreelgames.com/thank-you',`../checkup.php?asset_id=${cas.id}&keyword=${this.keyword}&gclid=${this.gclid}&matchtype=${this.matchtype}&device=${this.device}&adposition=${this.adposition}`)"
               target="_blank"
               data-product-id="8"
               :data-product-name="cas.name"
               rel="noindex nofollow">
              <img loading="lazy"
                   class="partner-logo ni-dxp1hc"
                   :src="cas.desktop_image"
                   :alt="cas.name"
                   width="207"
                   height="92" />
            </a>
          </div>

          <div data-testid="bonus-col" class="ni-9rha7u">
            <div class=" ni-xvcyas"
                 data-testid="bonus-text">

              <div style="line-height: 22px;">
                {{cas.bonus_1}} <br>
                {{cas.bonus_2}} <br v-if="cas.bonus_3">
                {{cas.bonus_3}} <br>
              </div>

              <div style="font-size:14px;" v-if="cas.coupon != 'none'">
                <button :id="'button-' + cas.id"
                        class="justatooltip top"
                        style="
                         border: none;
                         font-size: 14px;
                         color: #ffffff;
                         background-color: #00c9f5;
                         padding: 8px;
                         margin-top: 5px;
                         border-radius: 30px;
                        "
                        @click="copyToClipboard('#coupon-' + cas.id); toolTipChange('button-' + cas.id)"
                >
                  Coupon: <span :id="'coupon-' + cas.id" > {{cas.coupon}} </span>
                </button>
              </div>
            </div>
          </div>

          <div data-testid="ranking-column-container" class="ranking-column ni-1odf6bt">
            <div data-testid="visits" class="ni-1iqtukx">
              <span>Votes ({{votes[index]}})</span>
            </div>

           <span v-html="starRates(starRating[index])"> </span>

            <span style="color:#888888;font-weight: 600!important;">Our Score</span>
            <span style="color:#888888;font-weight: 600!important;">{{scoreWords[index]}}</span>
          </div>

          <div data-testid="score-column" class="ni-juckfh">
            <div class="ni-1hwawds">
              <div class="score-value" data-testid="score-value">{{score[index]}}</div>
              <div class="label-text" data-testid="label-text">{{scoreWords[index]}}</div>
            </div>
          </div>
          <div class="ni-9rha7u" data-testid="cta-column">

            <a class="nilink chart-cta-button ni-14bj88s "
               @click="gtag_report_conversion('https://spinreelgames.com/thank-you',`../checkup.php?asset_id=${cas.id}&keyword=${this.keyword}&gclid=${this.gclid}&matchtype=${this.matchtype}&device=${this.device}&adposition=${this.adposition}`)"
               target="_blank" data-product-id="891"
               :data-product-name="cas.name"
               title="Get Bonus"
               data-hook="" d
               ata-role="partner-cta" rel="noindex nofollow"
               data-testid="cta-button">
              <span class="cta-button__text">Get Bonus</span>
            </a>

            <a
                class="nilink visit-product-link ni-1mm3mb9"
                @click="gtag_report_conversion('https://spinreelgames.com/thank-you',`../checkup.php?asset_id=${cas.id}&keyword=${this.keyword}&gclid=${this.gclid}&matchtype=${this.matchtype}&device=${this.device}&adposition=${this.adposition}`)"
                target="_blank"
                data-product-id="891"
                data-product-name="BuzzLuck Casino"
                rel="noindex nofollow">Visit {{cas.name}}</a>
          </div>
        </div>
      </div>
      <!-- Casino Table -->

    </div>


    <section data-role="wysiwyg" class="ni-sgd1v0">
      <div>
        <div>
          <h2>Navigating the World of Online Casinos: A Guide to Finding Your Perfect 2023</h2>
          <p>
            With the growth of online gambling, it can be challenging to know where to start when choosing the right online casino.
            With so many options, it's necessary to understand what to look for to ensure a safe, enjoyable and rewarding gaming experience.
          </p>

          <h2> Here are some key factors to consider when selecting an online casino:</h2>
          <p>
                <span lang="en">
                    <strong>Game Selection: The Heart of Your Online Casino Experience</strong>
                </span>
          </p>
          <p>Game selection is one of the most important factors when choosing an online casino.
            A wide range of games ensures that you'll always have options and can switch things
            up whenever you feel like it. From classic slots to the latest table games, an online
            casino with a diverse game selection will keep you entertained for hours on end. It would
            help if you also looked for games from top-tier software providers, as these are known for
            their exceptional graphics, sound effects, and overall quality. In addition to popular favorites
            like blackjack, roulette, and baccarat, you should also look for innovative and exciting games that
            are unique to the online gaming world. This could include live dealer games, virtual reality games, and more.
            Having a wide range of games is fundamental for entertainment value, but it also means that you'll have access to a
            diverse range of betting limits, which is ideal for both low and high-rollers. So, when choosing an online casino, look
            for a wide range of games from top-tier software providers. This will ensure an enjoyable, exciting and varied gaming
            experience that will keep you coming back for more.
          </p>

          <h2>Payment Options: The Key to Effortless Online Gaming</h2>

          <p>
            Having secure and fast payment options is of the utmost importance when it comes to online gaming.
            You want to make deposits and withdrawals quickly and easily without worrying about your personal and financial information safety.
            When choosing an online casino, look for options that are both secure and convenient for you. This might include e-wallets, Visa, Mastercard,
            bank transfers or Crypto deposits and payouts. E-wallets like PayPal or ApplePay are particularly popular as they offer fast, secure and convenient
            transactions. In addition, it's essential to look for online casinos that offer fast payouts. No one wants to wait for days to receive their winnings,
            so look for an online casino that promises quick and hassle-free payouts. It's also a good idea to check for fees associated with different payment
            options. Some online casinos may charge fees for specific payment methods, so make sure to choose an option that is secure, convenient, and affordable.
            In summary, payment options are a crucial part of the online gaming experience, so choose an online casino that offers secure, fast, and convenient
            payment options that are right for you.
          </p>

          <h2>The Importance of Experienced Customer Support and Retention Teams</h2>

          <p>
            Access to reliable and knowledgeable customer support can make all the difference when it comes to online gaming.
            Whether you have a question about the games, a problem with your account, or need help with a technical issue, having
            an experienced customer support team can ensure a positive and enjoyable experience. This is why it's so crucial for online
            casinos to have experienced and well-trained customer support teams. A knowledgeable and friendly support team can help resolve
            any issues you may encounter and ensure a positive experience while playing. They should be available 24/7 via various communication
            channels such as email, live chat, and phone and be able to assist you in a timely and efficient manner. In addition to customer support,
            having a well-staffed retention department is also crucial for the success of an online casino. This department is responsible for keeping
            players engaged and satisfied with their gaming experience and developing and implementing strategies to keep players coming back.
            A strong retention team can help improve player loyalty and boost overall retention rates, which is why it's so required for online
            casinos to invest in this area. In conclusion, having experienced customer support and retention teams is essential for the success
            of an online casino. These departments play a crucial role in ensuring that players have a positive and enjoyable experience and can
            help keep players engaged and coming back for more. If you're looking for a top-notch online gaming experience, be sure to choose an
            online casino with a solid commitment to customer support and retention.

          </p>


          <h2>Unleash the Benefits of Bonuses and Promotions</h2>

          <p>
            Bonuses and promotions are important aspects of the online gaming experience.
            They offer players the chance to receive extra rewards and benefits and can add
            an extra layer of excitement to their gaming experience. When choosing an online casino,
            looking for one that offers excellent bonuses and promotions is essential. These can include welcome bonuses,
            deposit bonuses, free spins, and cashback offers. It's essential to check the terms and conditions of each bonus and
            promotion and to choose an online casino that offers fair and transparent offers. In addition to bonuses and promotions, a
            loyalty program can be a valuable way to earn rewards for your play. A good loyalty program should reward players for their activity,
            such as time spent playing games or money wagered. The more you play, the more rewards you can earn, making it a great way to enhance your
            online gaming experience. In conclusion, bonuses and promotions, along with a robust loyalty program, can add a lot of value to the online
            gaming experience. They offer players the chance to receive extra rewards and benefits and can help make the gaming experience more exciting
            and enjoyable. When choosing an online casino, look for one that offers generous bonuses and promotions and a loyalty program that rewards
            you for your play.

          </p>

          <h2>Take Your Online Gaming to the Next Level</h2>
          <p>
            No matter your preferred gaming style, there is always an opportunity to gamble on your favorite online casino games.
            Whether you prefer spinning the reels on the latest slots or taking a seat at the high-stakes VIP tables, there is always a
            way to play for real money and win big. We are delighted to help you choose the best online casino of our editors' choice.
          </p>

          <p>
                <span lang="en">
                    See you in the game,
                    <span style="display: block;">
                        Top USA Casinos
                    </span>
                </span>
          </p>
        </div>


      </div>


      <div id="club-signup-form" class="front-club-signup signup-block container">
        <div class="container">
          <div class="region region-club">
            <div id="block-block-8" class="block block-block block">
              <div class="content">
                <div class="sign-up-form-wrapper row">
                  <!-- Desktop -->
                  <!-- Subscribe content section -->
                  <div class="desktop col-md-5 col-lg-5 d-inline-flex center-flex white-parent float-lg-left float-md-left text-md-right">
                    <p class="bold font-medium line-height-7 text-shadow-6 mg-lg-2"
                       style="text-align: left!important;font-size:18px!important;">
                      Get The Latest Casino Bonuses and Our Top Recommendations Delivered to Your <br>
                      Inbox Every Week! Sign Up Now!
                    </p>
                  </div>
                  <!-- Subscribe content section -->

                  <!-- Form section-->
                  <div class="hide-768 col-md-1 d-inline-flex center-flex white-parent float-lg-left float-md-left font-large">
                    <i
                        class="fa fa-arrow-circle-right text-center"
                        style="
                                                font-size: 26px;
                                               "
                        aria-hidden="true">

                    </i>
                  </div>
                  <div class="col-md-6 d-inline-flex center-flex white-parent float-lg-right float-md-right text-left">
                    <div id="signup_form_fron" class="clearfix p-2">
                      <form target="blank" method="post" action="subscribes.php">
                        <input id="country" type="hidden" value="109.186.80.86" name="country">
                        <input id="email" type="email" value="" name="email" placeholder="Enter your e-mail adress" m="" class="p-2 line-height-7">
                        <button type="submit"
                                class="form-submit md-floar-right lg-float-right p-2 font-weight-semi-bold white-shadow signup-link"
                                style="border: none;background-color:#00c9f5;line-height: 12px; ">
                          Subscribe
                        </button>
                      </form>
                      <span id="error-message" style="color: red!important; font-size: 12px;"></span>

                    </div>
                    <div class="text-center">
                      <div class="p-2 text-sm-left text-md-left">
                        <p class="font-tiny mb-0" >You're in control - if you ever want to stop receiving our emails,
                          you can cancel your subscription at any time without any hassle!
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- End form section-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>

import jsonData from '@/data.json';


export default {
  name: "Main",
  data(){
    return {
      googleID: 'AW-11025650078',
      googleHASH: 'zfKxCJSunpMYEJ6juIkp',
      gclid: '',
      keyword: '',
      matchtype:'',
      device: '',
      adposition: '',
      casinos:[],
      ribbons: [
        "Editor's Choice",
        "Most Popular",
        "Hot & Trending",
        "Best Crypto"
      ],
      votes: [
        '9345',
        '8965',
        '8544',
        '8233',
        '7925',
        '7899',
        '7713',
        '7567',
      ],
      score: [
        '9.9',
        '9.9',
        '9.8',
        '9.7',
        '9.1',
        '8.9',
        '8.8',
        '8.5',
        '8.4',
      ],
      scoreWords: [
        'Excellent',
        'Excellent',
        'Excellent',
        'Very Good',
        'Very Good',
        'Good',
        'Good',
        'Good',
        'Good',
        'Good',
        'Good',
      ],
      starRating: [
        5,
        5,
        4.8,
        4.5,
        4.5,
        4.3,
        4.2,
        4,
        3.9,
        3.8,
      ],
    }
  },
  computed: {
    formattedDate() {
      const date = new Date();
      return date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      });
    },
    sortedCasinos() {
      // Filter and sort assets based on position value
      return this.casinos
          .filter(cas => cas.position !== 'none')
          .sort((a, b) => a.position - b.position);
    }
  },
  methods: {
    starRates(rate){
      if (rate >= 4.6 && rate <= 5) {
        return `<svg width="80" height="20" viewBox="0 0 74.5 12.5" xmlns="http://www.w3.org/2000/svg" data-testid="rating-stars" data-value="rate">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.69066 0C6.44818 0 4.48485 3.87937 4.48485 3.87937C4.48485 3.87937 0.0831377 4.4288 0.00153029 4.71567C-0.0799941 5.00254 3.12295 7.95094 3.12295 7.95094C3.12295 7.95094 2.36937 12.2171 2.55698 12.3469C2.74406 12.4766 6.69059 10.468 6.69059 10.468C6.69059 10.468 10.6341 12.4503 10.8233 12.3423C11.0119 12.2344 10.2583 7.95094 10.2583 7.95094C10.2583 7.95094 13.4853 4.92353 13.3798 4.71567C13.2742 4.50786 8.89805 3.87937 8.89805 3.87937C8.89805 3.87937 6.93158 0 6.69063 0H6.69066Z" fill="#ffd600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9496 0C21.7071 0 19.7438 3.87937 19.7438 3.87937C19.7438 3.87937 15.342 4.4288 15.2604 4.71567C15.1789 5.00254 18.3819 7.95094 18.3819 7.95094C18.3819 7.95094 17.6283 12.2171 17.8159 12.3469C18.003 12.4766 21.9495 10.468 21.9495 10.468C21.9495 10.468 25.893 12.4503 26.0822 12.3423C26.2708 12.2344 25.5173 7.95094 25.5173 7.95094C25.5173 7.95094 28.7442 4.92353 28.6387 4.71567C28.5331 4.50786 24.157 3.87937 24.157 3.87937C24.157 3.87937 22.1905 0 21.9495 0H21.9496Z" fill="#ffd600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M37.2099 0C36.9675 0 35.0041 3.87937 35.0041 3.87937C35.0041 3.87937 30.6024 4.4288 30.5208 4.71567C30.4393 5.00254 33.6422 7.95094 33.6422 7.95094C33.6422 7.95094 32.8887 12.2171 33.0763 12.3469C33.2633 12.4766 37.2099 10.468 37.2099 10.468C37.2099 10.468 41.1534 12.4503 41.3426 12.3423C41.5312 12.2344 40.7776 7.95094 40.7776 7.95094C40.7776 7.95094 44.0046 4.92353 43.899 4.71567C43.7935 4.50786 39.4173 3.87937 39.4173 3.87937C39.4173 3.87937 37.4509 0 37.2099 0H37.2099Z" fill="#ffd600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M52.4693 0C52.2269 0 50.2635 3.87937 50.2635 3.87937C50.2635 3.87937 45.8618 4.4288 45.7802 4.71567C45.6987 5.00254 48.9016 7.95094 48.9016 7.95094C48.9016 7.95094 48.1481 12.2171 48.3357 12.3469C48.5227 12.4766 52.4693 10.468 52.4693 10.468C52.4693 10.468 56.4128 12.4503 56.602 12.3423C56.7906 12.2344 56.037 7.95094 56.037 7.95094C56.037 7.95094 59.264 4.92353 59.1584 4.71567C59.0529 4.50786 54.6767 3.87937 54.6767 3.87937C54.6767 3.87937 52.7103 0 52.4693 0H52.4693Z" fill="#ffd600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M67.7283 0C67.4858 0 65.5225 3.87937 65.5225 3.87937C65.5225 3.87937 61.1207 4.4288 61.0391 4.71567C60.9576 5.00254 64.1605 7.95094 64.1605 7.95094C64.1605 7.95094 63.407 12.2171 63.5946 12.3469C63.7817 12.4766 67.7282 10.468 67.7282 10.468C67.7282 10.468 71.6717 12.4503 71.8609 12.3423C72.0495 12.2344 71.2959 7.95094 71.2959 7.95094C71.2959 7.95094 74.5229 4.92353 74.4174 4.71567C74.3118 4.50786 69.9356 3.87937 69.9356 3.87937C69.9356 3.87937 67.9692 0 67.7282 0H67.7283Z" fill="#ffd600"></path>
          </svg>`;
      } else if (rate >= 4.1 && rate <= 4.5) {
        return `<svg width="80" height="20" viewBox="0 0 74.5 12.5" xmlns="http://www.w3.org/2000/svg" data-testid="rating-stars" data-value="4.5">
            <path fill-rule="evenodd"
                  clip-rule="evenodd"
                   d="M6.69066 0C6.44818 0 4.48485 3.87937 4.48485 3.87937C4.48485 3.87937 0.0831377 4.4288 0.00153029 4.71567C-0.0799941 5.00254 3.12295 7.95094 3.12295 7.95094C3.12295 7.95094 2.36937 12.2171 2.55698 12.3469C2.74406 12.4766 6.69059 10.468 6.69059 10.468C6.69059 10.468 10.6341 12.4503 10.8233 12.3423C11.0119 12.2344 10.2583 7.95094 10.2583 7.95094C10.2583 7.95094 13.4853 4.92353 13.3798 4.71567C13.2742 4.50786 8.89805 3.87937 8.89805 3.87937C8.89805 3.87937 6.93158 0 6.69063 0H6.69066Z" fill="#ffd600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9496 0C21.7071 0 19.7438 3.87937 19.7438 3.87937C19.7438 3.87937 15.342 4.4288 15.2604 4.71567C15.1789 5.00254 18.3819 7.95094 18.3819 7.95094C18.3819 7.95094 17.6283 12.2171 17.8159 12.3469C18.003 12.4766 21.9495 10.468 21.9495 10.468C21.9495 10.468 25.893 12.4503 26.0822 12.3423C26.2708 12.2344 25.5173 7.95094 25.5173 7.95094C25.5173 7.95094 28.7442 4.92353 28.6387 4.71567C28.5331 4.50786 24.157 3.87937 24.157 3.87937C24.157 3.87937 22.1905 0 21.9495 0H21.9496Z" fill="#ffd600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M37.2099 0C36.9675 0 35.0041 3.87937 35.0041 3.87937C35.0041 3.87937 30.6024 4.4288 30.5208 4.71567C30.4393 5.00254 33.6422 7.95094 33.6422 7.95094C33.6422 7.95094 32.8887 12.2171 33.0763 12.3469C33.2633 12.4766 37.2099 10.468 37.2099 10.468C37.2099 10.468 41.1534 12.4503 41.3426 12.3423C41.5312 12.2344 40.7776 7.95094 40.7776 7.95094C40.7776 7.95094 44.0046 4.92353 43.899 4.71567C43.7935 4.50786 39.4173 3.87937 39.4173 3.87937C39.4173 3.87937 37.4509 0 37.2099 0H37.2099Z" fill="#ffd600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M52.4693 0C52.2269 0 50.2635 3.87937 50.2635 3.87937C50.2635 3.87937 45.8618 4.4288 45.7802 4.71567C45.6987 5.00254 48.9016 7.95094 48.9016 7.95094C48.9016 7.95094 48.1481 12.2171 48.3357 12.3469C48.5227 12.4766 52.4693 10.468 52.4693 10.468C52.4693 10.468 56.4128 12.4503 56.602 12.3423C56.7906 12.2344 56.037 7.95094 56.037 7.95094C56.037 7.95094 59.264 4.92353 59.1584 4.71567C59.0529 4.50786 54.6767 3.87937 54.6767 3.87937C54.6767 3.87937 52.7103 0 52.4693 0H52.4693Z" fill="#ffd600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M67.7283 0C67.4858 0 65.5225 3.87937 65.5225 3.87937C65.5225 3.87937 61.1207 4.4288 61.0391 4.71567C60.9576 5.00254 64.1605 7.95094 64.1605 7.95094C64.1605 7.95094 63.407 12.2171 63.5946 12.3469C63.7817 12.4766 67.7282 10.468 67.7282 10.468C67.7282 10.468 71.6717 12.4503 71.8609 12.3423C72.0495 12.2344 71.2959 7.95094 71.2959 7.95094C71.2959 7.95094 74.5229 4.92353 74.4174 4.71567C74.3118 4.50786 69.9356 3.87937 69.9356 3.87937C69.9356 3.87937 67.9692 0 67.7282 0H67.7283Z" fill="#d5d5d5"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M65.4849 3.87937C65.4849 3.87937 67.4482 0 67.6907 0L67.6906 10.468C67.6906 10.468 63.7441 12.4766 63.557 12.3469C63.3694 12.2171 64.1229 7.95094 64.1229 7.95094C64.1229 7.95094 60.92 5.00254 61.0015 4.71567C61.0831 4.4288 65.4849 3.87937 65.4849 3.87937Z"
            fill="#ffd600">
            </path>
        </svg>`;
      } else if (rate >= 3.6 && rate <= 4) {
        return `<svg width="80" height="20" viewBox="0 0 74.5 12.5" xmlns="http://www.w3.org/2000/svg" data-testid="rating-stars" data-value="4">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.69066 0C6.44818 0 4.48485 3.87937 4.48485 3.87937C4.48485 3.87937 0.0831377 4.4288 0.00153029 4.71567C-0.0799941 5.00254 3.12295 7.95094 3.12295 7.95094C3.12295 7.95094 2.36937 12.2171 2.55698 12.3469C2.74406 12.4766 6.69059 10.468 6.69059 10.468C6.69059 10.468 10.6341 12.4503 10.8233 12.3423C11.0119 12.2344 10.2583 7.95094 10.2583 7.95094C10.2583 7.95094 13.4853 4.92353 13.3798 4.71567C13.2742 4.50786 8.89805 3.87937 8.89805 3.87937C8.89805 3.87937 6.93158 0 6.69063 0H6.69066Z" fill="#ffd600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9496 0C21.7071 0 19.7438 3.87937 19.7438 3.87937C19.7438 3.87937 15.342 4.4288 15.2604 4.71567C15.1789 5.00254 18.3819 7.95094 18.3819 7.95094C18.3819 7.95094 17.6283 12.2171 17.8159 12.3469C18.003 12.4766 21.9495 10.468 21.9495 10.468C21.9495 10.468 25.893 12.4503 26.0822 12.3423C26.2708 12.2344 25.5173 7.95094 25.5173 7.95094C25.5173 7.95094 28.7442 4.92353 28.6387 4.71567C28.5331 4.50786 24.157 3.87937 24.157 3.87937C24.157 3.87937 22.1905 0 21.9495 0H21.9496Z" fill="#ffd600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M37.2099 0C36.9675 0 35.0041 3.87937 35.0041 3.87937C35.0041 3.87937 30.6024 4.4288 30.5208 4.71567C30.4393 5.00254 33.6422 7.95094 33.6422 7.95094C33.6422 7.95094 32.8887 12.2171 33.0763 12.3469C33.2633 12.4766 37.2099 10.468 37.2099 10.468C37.2099 10.468 41.1534 12.4503 41.3426 12.3423C41.5312 12.2344 40.7776 7.95094 40.7776 7.95094C40.7776 7.95094 44.0046 4.92353 43.899 4.71567C43.7935 4.50786 39.4173 3.87937 39.4173 3.87937C39.4173 3.87937 37.4509 0 37.2099 0H37.2099Z" fill="#ffd600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M52.4693 0C52.2269 0 50.2635 3.87937 50.2635 3.87937C50.2635 3.87937 45.8618 4.4288 45.7802 4.71567C45.6987 5.00254 48.9016 7.95094 48.9016 7.95094C48.9016 7.95094 48.1481 12.2171 48.3357 12.3469C48.5227 12.4766 52.4693 10.468 52.4693 10.468C52.4693 10.468 56.4128 12.4503 56.602 12.3423C56.7906 12.2344 56.037 7.95094 56.037 7.95094C56.037 7.95094 59.264 4.92353 59.1584 4.71567C59.0529 4.50786 54.6767 3.87937 54.6767 3.87937C54.6767 3.87937 52.7103 0 52.4693 0H52.4693Z" fill="#ffd600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M67.7283 0C67.4858 0 65.5225 3.87937 65.5225 3.87937C65.5225 3.87937 61.1207 4.4288 61.0391 4.71567C60.9576 5.00254 64.1605 7.95094 64.1605 7.95094C64.1605 7.95094 63.407 12.2171 63.5946 12.3469C63.7817 12.4766 67.7282 10.468 67.7282 10.468C67.7282 10.468 71.6717 12.4503 71.8609 12.3423C72.0495 12.2344 71.2959 7.95094 71.2959 7.95094C71.2959 7.95094 74.5229 4.92353 74.4174 4.71567C74.3118 4.50786 69.9356 3.87937 69.9356 3.87937C69.9356 3.87937 67.9692 0 67.7282 0H67.7283Z" fill="#d5d5d5"></path>
        </svg>`;
      } else {

        return `<svg width="80" height="20" viewBox="0 0 74.5 12.5" xmlns="http://www.w3.org/2000/svg" data-testid="rating-stars" data-value="4">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.69066 0C6.44818 0 4.48485 3.87937 4.48485 3.87937C4.48485 3.87937 0.0831377 4.4288 0.00153029 4.71567C-0.0799941 5.00254 3.12295 7.95094 3.12295 7.95094C3.12295 7.95094 2.36937 12.2171 2.55698 12.3469C2.74406 12.4766 6.69059 10.468 6.69059 10.468C6.69059 10.468 10.6341 12.4503 10.8233 12.3423C11.0119 12.2344 10.2583 7.95094 10.2583 7.95094C10.2583 7.95094 13.4853 4.92353 13.3798 4.71567C13.2742 4.50786 8.89805 3.87937 8.89805 3.87937C8.89805 3.87937 6.93158 0 6.69063 0H6.69066Z" fill="#ffd600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9496 0C21.7071 0 19.7438 3.87937 19.7438 3.87937C19.7438 3.87937 15.342 4.4288 15.2604 4.71567C15.1789 5.00254 18.3819 7.95094 18.3819 7.95094C18.3819 7.95094 17.6283 12.2171 17.8159 12.3469C18.003 12.4766 21.9495 10.468 21.9495 10.468C21.9495 10.468 25.893 12.4503 26.0822 12.3423C26.2708 12.2344 25.5173 7.95094 25.5173 7.95094C25.5173 7.95094 28.7442 4.92353 28.6387 4.71567C28.5331 4.50786 24.157 3.87937 24.157 3.87937C24.157 3.87937 22.1905 0 21.9495 0H21.9496Z" fill="#ffd600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M37.2099 0C36.9675 0 35.0041 3.87937 35.0041 3.87937C35.0041 3.87937 30.6024 4.4288 30.5208 4.71567C30.4393 5.00254 33.6422 7.95094 33.6422 7.95094C33.6422 7.95094 32.8887 12.2171 33.0763 12.3469C33.2633 12.4766 37.2099 10.468 37.2099 10.468C37.2099 10.468 41.1534 12.4503 41.3426 12.3423C41.5312 12.2344 40.7776 7.95094 40.7776 7.95094C40.7776 7.95094 44.0046 4.92353 43.899 4.71567C43.7935 4.50786 39.4173 3.87937 39.4173 3.87937C39.4173 3.87937 37.4509 0 37.2099 0H37.2099Z" fill="#ffd600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M52.4693 0C52.2269 0 50.2635 3.87937 50.2635 3.87937C50.2635 3.87937 45.8618 4.4288 45.7802 4.71567C45.6987 5.00254 48.9016 7.95094 48.9016 7.95094C48.9016 7.95094 48.1481 12.2171 48.3357 12.3469C48.5227 12.4766 52.4693 10.468 52.4693 10.468C52.4693 10.468 56.4128 12.4503 56.602 12.3423C56.7906 12.2344 56.037 7.95094 56.037 7.95094C56.037 7.95094 59.264 4.92353 59.1584 4.71567C59.0529 4.50786 54.6767 3.87937 54.6767 3.87937C54.6767 3.87937 52.7103 0 52.4693 0H52.4693Z" fill="#ffd600"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M67.7283 0C67.4858 0 65.5225 3.87937 65.5225 3.87937C65.5225 3.87937 61.1207 4.4288 61.0391 4.71567C60.9576 5.00254 64.1605 7.95094 64.1605 7.95094C64.1605 7.95094 63.407 12.2171 63.5946 12.3469C63.7817 12.4766 67.7282 10.468 67.7282 10.468C67.7282 10.468 71.6717 12.4503 71.8609 12.3423C72.0495 12.2344 71.2959 7.95094 71.2959 7.95094C71.2959 7.95094 74.5229 4.92353 74.4174 4.71567C74.3118 4.50786 69.9356 3.87937 69.9356 3.87937C69.9356 3.87937 67.9692 0 67.7282 0H67.7283Z" fill="#d5d5d5"></path>
        </svg>`;
      }
    },
    copyToClipboard(element) {
      const tempInput = document.createElement("input");
      document.body.appendChild(tempInput);
      tempInput.value = $(element).text();
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    },

    toolTipChange(button) {
      document.getElementById(button).classList.add("topafter");
      document.getElementById(button).classList.remove("top");
    },
    gtag_report_conversion(url, actualUrl) {
      var callback = function () {
        if (typeof(url) != 'undefined') {
        }
      };
      gtag('event', 'conversion', {
        'send_to': `${this.googleID}/${this.googleHASH}`,
        'event_callback': callback
      });
      window.open(actualUrl,'_blank');
      return false;
    }
  },
  mounted() {
    this.casinos = jsonData.casinos;

    const queryString = window.location.search;

    // Create a URLSearchParams object from the query string
    const params = new URLSearchParams(queryString);

    // Access individual parameters by their names
    const gclid       = params.get('gclid');
    const keyword     = params.get('keyword');
    const matchtype   = params.get('matchtype');
    const device      = params.get('device');
    const adposition  = params.get('adposition');

    this.gclid      = gclid;
    this.keyword    = keyword;
    this.matchtype  = matchtype;
    this.device     = device;
    this.adposition = adposition;

  }
}
</script>

<style scoped>
div#club-signup-form .form-submit {
  width: 28%;
  margin-left: -7px;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  border: 1px solid;
  border-color: #ccc #ccc #bbb;
  border-radius: 3px;
  background: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1;
  padding: 0.6em 1em 0.4em;
}

#email {
  background-color: #ffffff!important;
}




.white, .white-parent * {
  color: #fff !important;
}

.font-tiny, .font-tiny-parent * {
  font-size: 10pt !important;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-shadow-6 {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .6);
}

.line-height-7 {
  line-height: 20pt;
}

.font-medium {
  font-size: 18pt !important;
}

.font-large {
  font-size: 30pt !important;
}

@media only screen and (max-width: 768px) {
  .hide-768 {
    display: none !important;
  }

  div#club-signup-form {
    /*background: url(https://res.cloudinary.com/getmagnet/image/upload/v1565099837/us2/nlbg.jpg);*/
    padding: 50px 0;
    text-align: center;
  }
}

.bold {
  font-weight: 600;
}

.white, .white-parent * {
  color: #fff !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

p {
  margin: 0 0 20px;
}

div#club-signup-form {
  /*background-color: rgba(10, 10, 10, 0.6);*/
  padding: 10px 0;
  text-align: center;
}

.center-flex {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

div#club-signup-form input {
  height: 29px;
  margin: 0;
  color: #222 !important;
  box-sizing: border-box;
  width: 69%;
  float: left;
  border: none;
  border-radius: 3px;
  margin-right: 1%;
  background: #fff url(https://image.flaticon.com/icons/svg/1034/1034291.svg) 2% 50% no-repeat;
  background-size: 22px;
  text-indent: 1.5rem;
}

div#club-signup-form .form-submit {
  width: 29%;
}


button.form-submit {
  display: inline-block;
  width: 20%;
  background: #00cb0f;
  /*background: -moz-linear-gradient(top, rgba(251, 186, 71, 1) 0%, rgba(248, 176, 49, 1) 100%);*/
  /*background: -webkit-linear-gradient(top, rgba(251, 186, 71, 1) 0%, rgba(248, 176, 49, 1) 100%);*/
  /*background: linear-gradient(to bottom, rgba(251, 186, 71, 1) 0%, rgba(248, 176, 49, 1) 100%);*/
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fbba47', endColorstr='#f8b031', GradientType=0);
  color: #ffffff !important;
  cursor: pointer;
  border-radius: 3px;
  text-align: center;
  /*border: 1px solid #c56722;*/
  box-shadow: 0px 2px 3px rgba(101, 50, 13, .25);
}


.justatooltip {
  border-radius: 10px;
  padding: 10px 15px;
  position: relative;
  margin: 15px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .justatooltip {
    border-radius: 10px;
    padding: 10px 15px;
    position: relative;
    margin: 8px;
    text-align: center;
    background: #56d141;
    border: none;
    font-weight: bold;
    font-size: 11px;
  }
}

.justatooltip::after {
  background-color: #333;
  border-radius: 10px;
  color: #fff;
  display: none;
  padding: 10px 15px;
  position: absolute;
  text-align: center;
  z-index: 999;
}

.justatooltip::before {
  background-color: #333;
  content: ' ';
  display: none;
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: 999;
}

.justatooltip:hover::after {
  display: block;
}

.justatooltip:hover::before {
  display: block;
}

.justatooltip.top::after {
  content: 'Copy Coupon';
  top: 0;
  left: 50%;
  width: 125px;
  transform: translate(-50%, calc(-100% - 10px));
}

.justatooltip.top::before {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.justatooltip.topafter::after {
  content: 'Copied :)';
  top: 0;
  left: 50%;
  width: 125px;
  transform: translate(-50%, calc(-100% - 10px));
}

.justatooltip.topafter::before {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.justatooltip.bottom::after {
  content: 'bottom';
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
}

.justatooltip.bottom::before {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 5px)) rotate(45deg);
}

.justatooltip.right::after {
  content: 'right';
  top: 0;
  right: 0;
  transform: translateX(calc(100% + 10px));
}

.justatooltip.right::before {
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 5px), -50%) rotate(45deg);
}

.justatooltip.left::after {
  content: 'left';
  top: 0;
  left: 0;
  transform: translateX(calc(-100% - 10px));
}

.justatooltip.left::before {
  top: 50%;
  left: 0;
  transform: translate(calc(-100% - 5px), -50%) rotate(45deg);
}

.ni-1sg2lsz {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

</style>

