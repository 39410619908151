import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import jquery from 'jquery'
import popper from 'popper.js'

import { createApp } from 'vue'
import App from './App.vue'

window.$ = jquery
window.jQuery = jquery
window.Popper = popper



createApp(App).mount('#app')
